<template>
  <!-- table -->
  <xm-flex>
    <xm-flex-item>
      <xm-search-box>
        <el-select v-model="search.templateGroupId" placeholder="请选择类型" size="small" @change="searchData">
          <el-option v-for="item in selector.templateGroupList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-button size="small" type="primary" @click="add">新增模板</el-button>
      </xm-search-box>
    </xm-flex-item>
    <xm-flex-item><xm-total-count>{{`商品板列表(${totalCount}条)`}}</xm-total-count></xm-flex-item>
    <xm-flex-item :flex="1">
      <xm-table-plus :query-data="queryData" ref="table">
        <el-table-column prop="id" label="ID" width="80"></el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column label="封面图" width="92">
          <template #default="scope">
            <el-image style="width: 70px; height: 70px" :src="scope.row.image" fit="contain" :preview-src-list="[scope.row.image]" :initial-index="1"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="desc" label="说明"></el-table-column>
        <el-table-column prop="course.name" label="商品名"></el-table-column>
        <el-table-column label="状态" width="70">
          <template #default="scope">
            <el-tag type="primary" size="small" v-if="scope.row.flag === 1">显示</el-tag>
            <el-tag type="info" size="small" v-else>隐藏</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="220">
          <template #default="scope">
            <el-button type="text" size="small" @click="modify(scope.row)">修改</el-button>
            <el-button type="text" size="small" @click="setFlag(scope.row.id, scope.row.flag)">{{scope.row.flag===1?'隐藏':'显示'}}</el-button>
            <el-dropdown @command="handleCommand">
              <el-button type="text" size="small" class="el-dropdown-link">更多</el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :command="{name: 'move', id: scope.row.id, action: 'up'}" v-if="!scope.row._isFirst">上移</el-dropdown-item>
                  <el-dropdown-item :command="{name: 'move', id: scope.row.id, action: 'down'}" v-if="!scope.row._isLast">下移</el-dropdown-item>
                  <el-dropdown-item :command="{name: 'move', id: scope.row.id, action: 'top'}" v-if="!scope.row._isFirst">置顶</el-dropdown-item>
                  <el-dropdown-item :command="{name: 'move', id: scope.row.id, action: 'bottom'}" v-if="!scope.row._isLast">置底</el-dropdown-item>
                  <el-dropdown-item :command="{name: 'clear', id: scope.row.id}">删除</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </xm-table-plus>
    </xm-flex-item>
  </xm-flex>
  <!-- dialog -->
  <el-dialog v-model="dialog.visible" :title="dialog.title" destroy-on-close>
    <el-form ref="ruleForm" :model="dialog.data" label-width="auto">
      <!-- 名称 -->
      <el-form-item label="名称" prop="name" :rules="[
        {required:true, message: '名称不能为空',trigger: 'blur'}
      ]">
        <el-input v-model="dialog.data.name" size="small" maxlength="128" clearable></el-input>
      </el-form-item>
      <!-- 类型 -->
      <el-form-item label="类型" prop="templateGroupId" :rules="[
        {required:true, message: '类型不能为空',trigger: 'blur'}
      ]">
        <el-select v-model="dialog.data.templateGroupId" placeholder="请选择类型" size="small" clearable>
          <el-option v-for="item in selector.templateGroupList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <!-- 名称 -->
      <el-form-item label="商品id" prop="pid" :rules="[
        {required:true, message: '商品id不能为空',trigger: 'blur'}
      ]">
        <el-input v-model="dialog.data.pid" size="small" maxlength="16" clearable></el-input>
      </el-form-item>
      <!-- 描述 -->
      <el-form-item label="描述" prop="name" :rules="[
        {required:true, message: '描述不能为空',trigger: 'blur'}
      ]">
        <el-input v-model="dialog.data.desc" size="small" maxlength="128" clearable></el-input>
      </el-form-item>
      <!-- 封面图片 -->
      <el-form-item label="封面图" prop="image" :rules="[
        {required: !dialog.data.miniTemplateId, message: '封面图不能为空', trigger: 'blur'}
      ]">
        <el-upload action="" :http-request="uploadImage" :on-exceed="exceedImage" :limit="1" list-type="picture">
          <el-button size="small" type="default">上传图片</el-button>
        </el-upload>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="dialog.visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="confirm" size="small">确定</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { ElButton, ElDialog, ElSelect, ElOption, ElTableColumn, ElForm, ElFormItem, ElInput, ElDropdown, ElDropdownItem, ElDropdownMenu, ElUpload, ElImage, ElTag } from 'element-plus'
import axios from '@/common/utils/axios'
import { onMounted, reactive, ref } from 'vue'
import type from '@/common/utils/type'
import upload from '@/common/utils/upload'

function table () {
  const search = reactive({
    templateGroupId: ''
  })
  const selector = reactive({
    templateGroupList: [],
    miniTemplateList: []
  })
  /**
     * 表单信息
     */
  const table = ref(null)
  const totalCount = ref(0)
  /**
     * 查询表单列表
     */
  const queryData = (page, pageSize) => {
    return new Promise((resolve) => {
      axios('/manager/mini/template/list', {
        templateGroupId: search.templateGroupId,
        page,
        pageSize
      })
        .then(res => {
          totalCount.value = res.totalCount
          table.value.judgePosition(res.miniTemplateList, res.totalCount)
          resolve({
            list: res.miniTemplateList,
            totalCount: res.totalCount
          })
        }).catch(_ => { })
    })
  }
  /**
   * 搜索模板
   */
  const searchData = () => {
    table.value.reload()
  }
  /**
     * 查询枚举--模板类型
     */
  const queryType = () => {
    return new Promise((resolve) => {
      axios('/manager/template/group/list/all', { type: type().templateGroupType.goods })
        .then(res => {
          res.templateGroupList.forEach(item => {
            selector.templateGroupList.push(item)
          })
          search.templateGroupId = res.templateGroupList[0].id
          resolve()
        }).catch(_ => { })
    })
  }
  onMounted(async () => {
    await queryType()
    table.value.reload()
  })

  return {
    search,
    selector,
    table,
    queryData,
    totalCount,
    searchData
  }
}

function dialog () {
  const ruleForm = ref(null)
  const dialog = reactive({
    visible: false,
    title: '',
    data: {}
  })
  return {
    ruleForm,
    dialog
  }
}

function templateGroupDialog () {
  const templateGroupRuleForm = ref(null)
  const templateGroupDialog = reactive({
    visible: false,
    title: '',
    templateGroupList: null,
    data: {
      institutionTemplateId: '',
      templateGroupId: ''
    }
  })
  const queryTemplateGroupList = type => {
    return new Promise((resolve) => {
      axios('/manager/template/group/list/all', {
        type
      })
        .then(res => {
          resolve(res.templateGroupList || [])
        }).catch(_ => { })
    })
  }
  return {
    templateGroupRuleForm,
    templateGroupDialog,
    queryTemplateGroupList
  }
}

function operation (t, d, tgd) {
  /**
     * 新增
     */
  const add = () => {
    d.dialog.visible = true
    d.dialog.title = '新增模板'
    d.dialog.data = {
      miniTemplateId: '',
      templateGroupId: t.search.templateGroupId,
      type: type().miniTemplateType.goods,
      pid: '',
      setmealId: 0,
      name: '',
      image: '',
      desc: ''
    }
  }
  /**
     * 修改
     */
  const modify = (row) => {
    d.dialog.visible = true
    d.dialog.title = '修改模板'
    d.dialog.data = {
      miniTemplateId: row.id,
      templateGroupId: row.templateGroupId,
      type: type().miniTemplateType.goods,
      pid: row.course.id,
      setmealId: 0,
      name: row.name,
      image: row.image,
      desc: row.desc
    }
  }
  /**
   * 保存企业模板
   */
  const confirm = () => {
    d.ruleForm.value.validate(valid => {
      if (!valid) {
        return
      }
      submit()
    })
  }
  const submit = () => {
    axios('/manager/mini/template/save', d.dialog.data)
      .then(res => {
        window.$message({
          message: '操作成功',
          type: 'success'
        })
        d.dialog.visible = false
        t.table.value.reloadCurrent()
      }).catch(_ => { })
  }
  /**
   * 更改状态
   */
  const setFlag = (id, flag) => {
    axios('/manager/mini/template/flag/set', {
      miniTemplateId: id,
      flag: flag === 1 ? 0 : 1
    }).then(res => {
      window.$message({
        message: '操作成功',
        type: 'success'
      })
      t.table.value.reloadCurrent()
    })
  }
  /**
     * 指令列表
     */
  const handleCommand = (command) => {
    commandList[command.name](command.id, command.action)
  }
  const commandList = {
    clear: (id) => { // 删除
      window.$confirm('是否确认删除').then(() => {
        del(id)
      }).catch(_ => { })
    },
    move: (id, action) => {
      axios('/manager/mini/template/move', {
        miniTemplateId: id,
        move: action
      }).then(res => {
        window.$message({
          message: '操作成功',
          type: 'success'
        })
        t.table.value.reload()
      })
    }
  }
  const del = (id) => {
    axios('/manager/mini/template/del', {
      miniTemplateId: id
    })
      .then(res => {
        window.$message({
          message: '操作成功',
          type: 'success'
        })
        t.table.value.reloadCurrent()
      }).catch(_ => { })
  }
  /**
   * 上传图片
   */
  const uploadImage = ({ file }) => {
    upload(file, '0', (url) => {
      d.dialog.data.image = url
    })
  }
  /**
   * 超出数量提示
   */
  const exceedImage = () => {
    window.$message({
      message: '图片超出数量，请删除后上传',
      type: 'error'
    })
  }

  return {
    add,
    modify,
    confirm,
    setFlag,
    handleCommand,
    uploadImage,
    exceedImage
  }
}

export default {
  components: { ElButton, ElDialog, ElSelect, ElOption, ElTableColumn, ElForm, ElFormItem, ElInput, ElDropdown, ElDropdownItem, ElDropdownMenu, ElUpload, ElImage, ElTag },

  setup () {
    const t = table()
    const d = dialog()
    const tgd = templateGroupDialog()
    const o = operation(t, d, tgd)
    return {
      ...t,
      ...d,
      ...tgd,
      ...o
    }
  }
}
</script>
<style lang="scss" scoped>
.button-group {
  text-align: right;
}
:deep .tox-tinymce{
  height: calc(100% - 20px) !important;
}
.el-dropdown .el-button {
  margin-left: 10px;
}
.drawer-button {
  padding: 10px 0;
}
</style>
